export default function QueryInput({Q}) {
    const [query, setQuery] = Q;
    return (
        <input
        type="search"
        placeholder="Ingredients"
        autoComplete="Off"
        className="app__input"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
      />
    );

}

