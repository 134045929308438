import "./App.css";
import { useState } from "react";
import Axios from "axios";
import RecipeTile from "./recipetile/Recipetile";
import GetHealthLabel from './SelectHealthLabel';
import QueryInput from './QueryInput';
import { FcAbout } from "react-icons/fc";
import About from './About';
import  TT from './TT';

function App() {
  const YOUR_APP_ID = "7184cf21";
  const YOUR_APP_KEY = "a9337d6a73353c7c9921eb69850b411c";
  const [query, setQuery] = useState("");
  const [healthLabel, setHealthLabel] = useState("vegetarian");
  const [recipes, setRecipes] = useState([]);
  const [error, setError] = useState('');
  const [favorites, setFavorites] = useState([]);
  const [showFavs, setShowFavs] = useState(false);
  const [about, setAbout] = useState(false);
  const url = `https://api.edamam.com/search?q=${query}&app_id=${YOUR_APP_ID}&app_key=${YOUR_APP_KEY}&health=${healthLabel}`;

  //console.log(process.env.RECIPES_APP_ID,process.env.RECIPES_APP_KEY );

  const getRecipeInfo = async () => {
      Axios.get(url)
      .then (result =>
        (result.data.hits ? setRecipes(result.data.hits) : setError('fetch error'))
       )
      .catch(error =>setError(error));
  };

  const onSubmit = (e) => {
    e.preventDefault(); //this will prevent page from reloading.
    if (recipes.length === 0) {
      getRecipeInfo();
    } else {
      setRecipes([]);
    }
    
  };

  const onSelection = (e) => {
    setHealthLabel(e.target.value);
  }

  const GetAbout = (e) => {
    e.stopPropagation();
    setAbout(prev=>!prev);
  };

  const recipesShow = showFavs ? favorites : recipes;

  if (error) {
    return  <div>{error}</div>;
  }
  

  return (
    <div className="app">
      <h1>
        Healthy Food Recipe Hub 
        <span style={{cursor: 'pointer'}} onClick={ getRecipeInfo}><TT r={'🥗'} s={'get recipes'} /></span> 
        <TT r={<span style={{cursor: 'pointer', marginTop:'20px'}} onClick={ (e)=>GetAbout(e)}>< FcAbout /></span>} s='About' />
      </h1>
      <About about={about} setAbout={setAbout} />
      <form className="app__searchForm" onSubmit={onSubmit}>
        <QueryInput Q={[query, setQuery]} />
        <TT r={<GetHealthLabel  onSelection={onSelection}/>} s='health diet labels' />
        <
          input 
          type="submit" 
          value={recipes.length === 0 ? "Get Recipes" : "Clear Recipes"} 
          className="app__submit" 
        />
        <
          TT
          r={
            <
              button 
              className="app__favorites " 
              onClick={()=>{setShowFavs(!showFavs)}}
            >
              {showFavs ? 'Hide Favs' : 'Show Favs'}
            </button>
          }
          s={showFavs ? 'Hide Favorites' : 'Show Favorites'}
        />
      </form>
      <div className="app__recipes">
        {recipesShow.map((recipe, index) => {
          return <RecipeTile key={index} recipe={recipe} favorites={ favorites} setFavorites={setFavorites}/>;
        })}
      </div>
    </div>
  );
}

export default App;
