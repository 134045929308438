import {healthLabels} from './HealthLabels';

export default function GetHealthLabel({onSelection}) {
    return (
    <select  className="app__healthLabels" onChange={onSelection}>
      <option value="vegetarian" >
        vegetarian
      </option>
      <option value="vegan" >
        vegan
      </option>
      <option value="low-sugar" >
        low-sugar
      </option>
      <option value="dairy-free" >
        dairy-free
      </option>
      <option value="wheat-free" >
        wheat-free
      </option>
    </select>
    );

  
}
/* 
  {healthLabels.map(lbl =>
        <option value={lbl} >
        {lbl}
        </option>
        )}
       
*/