import React from "react";
import "./style.css";
import { MdFavoriteBorder } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import TT from '../TT';
import {T} from '../PopUpJK';

function RecipeTile({ recipe,favorites, setFavorites }) {

  const isInFav = function (recipe) {
    return favorites.some(f => f.recipe.url === recipe.recipe.url);
  }

  const sendToFavs = (recipe) => {
    if (! isInFav(recipe)) {
      setFavorites([recipe, ...favorites]);
      //alert('a recipe to Favorites!');
    }
  };
  const removeFromFavs = (recipe) => {
    if (isInFav(recipe)) {
      let lessFavs = favorites.filter(f => f.recipe.url !== recipe.recipe.url );
      setFavorites(lessFavs);
      //alert('a recipe removed from Favorites!');
    }
  };

  return (
    (
      <div className="recipeTile">
        <T r={
        <img
          className="recipeTile__image"
          src={recipe["recipe"]["image"]}
          alt="food"
          title={recipe["recipe"]["url"]}
          onClick={() => window.open(recipe["recipe"]["url"])}
        />
        }
        s={recipe["recipe"]["url"]}
        />
        <p className="recipeTile__name">
          &nbsp;
          {
            (! isInFav(recipe)) ?
            <TT r={<MdFavoriteBorder style={{ cursor: 'pointer'}}  onClick={() => sendToFavs(recipe)} />} s='to Favorites' />
            :
            <TT r={<MdFavorite style={{ cursor: 'pointer'}} onClick={() => removeFromFavs(recipe)}/>} s='remove from Favorite' />
          }
          &nbsp;
          {recipe["recipe"]["label"]} 
        </p>
        <br />
      </div>
    )
  );
}

//recipe["recipe"]["image"].match(/\.(jpeg|jpg|gif|png)$/) != null && 
export default RecipeTile;
