const aboutText = 
<>
This is a simple Healthy Food Recipe Hub. Check tool tips for testing.<br />
1. The data come from https://api.edamam.com/search... <br />
2. To get recipes, type in ingredients, and choose health option and press the 'Get Recipes' button.<br />
3. To collect your favorite recipes, press the 'heart' button of the recipe item.<br />
4. To see the favorites page, press the 'Show Favs' button.<br />
4. To find a detailed recipe, press the recipe picture. <br />
</>;

export default aboutText;