import './tt.css';

const TT = ({r, s}) => {
    return (
      <span className="tooltip">
        {r}
        <span className="tooltiptext">
          <pre>{s}</pre>
        </span>
      </span>
    );
};

export default TT;